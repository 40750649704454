import WL from 'constants/whiteLabel';
import languages from 'constants/languages';

export const getLanguage = () => {
  let locale = WL.defaultLang || 'en';

  if (typeof(localStorage) !== 'undefined') {
    const langauage = localStorage.getItem('language');

    if(!langauage) {
      // locale = WL.checkBrowserLang
      //   ? (typeof(window) !== 'undefined' && window.navigator ? window.navigator.language : (WL.defaultLang || 'en')).substr(0, 2).toUpperCase()
      //   : locale;
    } else {
      locale = langauage;
    }

    localStorage.setItem('language', locale);
  }

  return locale;
};

export const getExcludedLangs = (currentLang = languages.EN, excludeAll = false) => {
  if (excludeAll) { 
    return [languages.EN, languages.RU, languages.ZH]; 
  }
  if (currentLang === languages.ZH) {
    return [languages.EN, languages.RU];
  }
  return [languages.ZH];
}
