import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as L } from 'react-intl';
import FB from 'utils/images/facebook.svg';
import IN from 'utils/images/Linkedin.svg';
import TW from 'utils/images/twitter.svg';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import * as styles from './shareArticle.module.css';

const ShareArticle = ({ title, path }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexRow}>
        <span><L id="share" defaultMessage="Share" /></span>
        <TwitterShareButton url={path} title={title} className={styles.btn}>
          <img src={TW} alt="Twitter" width={28}/>
        </TwitterShareButton>
        <FacebookShareButton url={path} title={title} className={styles.btn}>
          <img src={FB} alt="Facebook" width={24}/>
        </FacebookShareButton>
        <LinkedinShareButton url={path} title={title} className={styles.btn}>
          <img src={IN} alt="Linkedin" width={24}/>
        </LinkedinShareButton>
      </div>
    </div>
  )
};

ShareArticle.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default ShareArticle;
