import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import * as s from './mdxlink.module.css';

// NOT USED AS UI COMPONENT IN MDX, ONLY FOR MDX PROVIDER!

const MDXLink = ({ children, href, ...props }) => {
  if (href && href.startsWith('/')) {
    // Use Gatsby's Link component for internal site navigation
    // to benefit from the preloading features
    // See: https://www.gatsbyjs.org/docs/gatsby-link/
    return <GatsbyLink to={href} className={s.link} {...props}>{children}</GatsbyLink>;
  }
  // Check if the link is for a section on the page
  // We don't want to add the attributes for the on page links
  const onPage = href && href.startsWith('#');

  /* eslint-disable */
  return (
    <a
      href={href}
      target={onPage ? null : '_blank'}
      rel={onPage ? null : 'nofollow noopener noreferrer'}
      className={s.link}
      {...props}
    >
      {children}
    </a>
  );
};

export default MDXLink;
