import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from '@mdx-js/react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import moment from 'moment';
import Layout from 'components/layout';
import Link from 'components/Link';
// import MoreArticles from 'components/MoreArticles';
import ShareArticle from 'components/ShareArticle';
import MDXLink from 'components/mdx/MDXLink';
import { MAIN_IMG } from 'constants/index'
import { ARTICLE_PREVIEW_IMG_NONE } from 'constants/blog';
import { getExcludedLangs } from 'helpers/language';
import * as s from './articleMdx.module.css';
import {FormattedMessage} from 'react-intl';

const Article = ({ data, ...props }) => {
  const { mdx } = data;
  const { frontmatter, body } = mdx;
  // const blogData = _get(props, 'pageContext.blogData', []);
  const formatedDate = moment(frontmatter.date).format('DD.MM.YYYY');
  const currentLang = _get(props, 'pageContext.currentLang', 'en');
  const availableLangs = _get(props, 'pageContext.availableLangs', []);
  const hideLangSwitcher = availableLangs.length <= 1;

  const getMeta = useCallback(
    () => {
      const title = _get(frontmatter, 'meta_title') || _get(frontmatter, 'title', '');

      const metaDescription = _get(
        frontmatter,
        'meta_description',
      );

      const img = frontmatter.previewImg && frontmatter.previewImg !== ARTICLE_PREVIEW_IMG_NONE ?
        frontmatter.previewImg :
        MAIN_IMG;

      const path = `https://hiveon.com${frontmatter.path}`;

      return {
        title,
        meta: [
          { name: 'description', content: metaDescription || '' },
          { property: 'og:title', content: title },
          { property: 'og:image', content: img },
          { property: 'og:description', content: metaDescription || '' },
          { property: 'og:type', content: 'article' },
          { property: 'og:url', content: path },
          { property: 'twitter:image', content: img },
        ]
      }
    },
    [frontmatter],
  );

  // TODO: return excludedLangs={getExcludedLangs(currentLang, hideLangSwitcher)} but with smarter logic
  // It should check if article exists
  return (
    <Layout
      page="blog"
      extraTitle="Blog"
      customMeta={getMeta()}
      pageName={frontmatter.path}
      lang={currentLang}
      registrationURL={_get(props, 'pageContext.registrationURL', '')}
    >
      <div itemScope itemType="https://schema.org/NewsArticle" className={s.wrapper}>
        <div className={s.rootArticle}>
          {
            frontmatter.previewImg && frontmatter.previewImg !== ARTICLE_PREVIEW_IMG_NONE && (
              <div itemProp="image" itemScope itemType="https://schema.org/ImageObject">
                <img
                  src={frontmatter.previewImg}
                  alt={`HiveOS — ${frontmatter.title}`}
                  itemProp="url contentUrl"
                />
                <meta itemProp="width" content="1200" />
                <meta itemProp="height" content="630" />
              </div>
            )
          }
          <div className={s.row}>
            <nav aria-label="breadcrumb">
              <ol className={s.breadcrumb}>
                <li className={s.breadcrumbItem}>
                  <Link to={'/blog'}>
                    <FormattedMessage id="meta.blogTitle" />
                  </Link>
                </li>
                <li className={s.breadcrumbItem}><FormattedMessage id="blog.news" /></li>
              </ol>
            </nav>
          </div>
          <div className={s.row}>
            <time className={s.time} dateTime={frontmatter.date} itemProp="datePublished">{formatedDate}</time>
          </div>
          <h1 itemProp="headline">{frontmatter.title}</h1>
          <MDXProvider
            components={{
              a: MDXLink,
            }}
          >
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
          <span>
            <ShareArticle title={frontmatter.title} path={`https://hiveon.com/${frontmatter.path}`} />
          </span>
          {/*{ false && <MoreArticles currentArticle={frontmatter.path} blogData={blogData} /> }*/}
        </div>
      </div>
    </Layout>
  )
};

export const query = graphql`
  query($slug: String!, $locale: String!) {
    mdx(
      frontmatter: {lang: {eq: $locale}}
      fields: { slug: {eq: $slug} }
    ) {
      body
      frontmatter {
        title
        path
        previewImg
        meta_description
        meta_title
        date
      }
    }
  }
`;

Article.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Article;
